<template>
  <div
    class="form-group"
    :class="[
      { 'input-group': hasIcon },
      { 'has-danger': (requireError || errorMsg) && !hideError },
      { 'input-group-focus': focused },
      { 'has-label': label || $slots.label },
    ]"
  >
    <slot name="label">
      <label
        v-if="label"
        class="form-label ml-2"
        :for="id"
        :class="labelClasses"
      >
        {{ label }}
      </label>
    </slot>

    <div class="mb-0" :class="[{ 'input-group': hasIcon }]">
      <slot name="addonLeft">
        <div v-if="addonLeftIcon" class="input-group-prepend">
          <i class="input-group-text" :class="addonLeftIcon"></i>
        </div>
      </slot>
      <slot>
        <input
          :id="id"
          :value="value"
          v-on="listeners"
          v-bind="$attrs"
          class="form-control"
          :class="[{ valid: value && !error }, inputClasses]"
          aria-describedby="addon-right addon-left"
          :readonly="readonly"
          v-on:input="requireValidation"
          ref="input"
        />
      </slot>
    </div>
    <transition name="slide-fade">
      <div
        v-show="
          (errorMsg && !hideError) ||
          (!hideError && required ? requireError : false)
        "
      >
        <div
          class="input-message"
          v-if="!hideError && required ? requireError : false"
        >
          This field is mandatory
        </div>
        <div v-else class="input-message">
          {{ errorMsg }}
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "fg-input",
  props: {
    required: Boolean,
    label: String,
    error: String,
    readonly: Boolean,
    labelClasses: String,
    inputClasses: String,
    id: String,
    value: {
      type: [String, Number],
      default: "",
    },
    addonRightIcon: String,
    addonLeftIcon: String,
    errorMsg: String,
    hideError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
      requireError: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
  },

  watch: {
    hideError(hide) {
      !hide ? this.requireValidation() : null;
    },
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    },
    requireValidation() {
      if (this.required) {
        this.requireError = !this.$refs.input.value;
        this.$emit("validation", !this.requireError);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.my-file-input:hover {
  cursor: pointer;
}

.input-message {
  margin: 0px 8px;
  font-size: 12px;
  color: red;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
