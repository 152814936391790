<template>
  <div id="app">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <!-- <router-view name="footer" /> -->
    <waves-footer></waves-footer>
  </div>
</template>
<script>
import WavesFooter from "./layout/WavesFooter.vue";

export default {
  components: {
    WavesFooter,
  },
};
</script>

<style>
#body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
