<template>
  <div class="card find-card" style="border-radius: 25px">
    <div class="card-header text-center mt-4">
      <h3 style="color: white">Find a job</h3>
    </div>

    <div class="px-3 text-center">
      <collapse>
        <collapse-item no-icon class="card-header">
          <h6 slot="title" class="mb-0" style="color: white">
            Job type
            <i
              class="now-ui-icons arrows-1_minimal-down"
              style="color: white"
            />
          </h6>
          <div v-if="availableTypes.length > 0" class="row">
            <div class="col pl-4" style="text-align: start">
              <n-checkbox
                v-model="allJobs"
                style="color: white"
                :dark="true"
                @change="changeAllJobs(allJobs)"
              >
                All
              </n-checkbox>
            </div>
          </div>
          <div class="row" v-for="job in availableTypes" :key="job.label">
            <div class="col pl-4" style="text-align: start">
              <n-checkbox
                v-model="job.selected"
                style="color: white"
                :dark="true"
                @change="changeJob(job)"
              >
                {{ job.label }} ({{ job.count }})
              </n-checkbox>
            </div>
          </div>
          <div v-if="availableTypes.length == 0" style="color: white">
            No jobs available
          </div>
        </collapse-item>
        <collapse-item no-icon class="card-header">
          <h6 slot="title" class="mb-0" style="color: white">
            Locations
            <i
              class="now-ui-icons arrows-1_minimal-down"
              style="color: white"
            />
          </h6>
          <div v-if="availablePlaces.length > 0" class="row">
            <div class="col pl-4" style="text-align: start">
              <n-checkbox
                v-model="allLocations"
                style="color: white"
                :dark="true"
                @change="changeAllLocations(allLocations)"
              >
                All
              </n-checkbox>
            </div>
          </div>
          <div class="row" v-for="place in availablePlaces" :key="place.label">
            <div class="col pl-4" style="text-align: start">
              <n-checkbox
                v-model="place.selected"
                style="color: white"
                :dark="true"
                @change="changePlace(place)"
              >
                {{ place.label }} ({{ place.count }})
              </n-checkbox>
            </div>
          </div>
          <div v-if="availablePlaces.length == 0" style="color: white">
            No locations available
          </div>
        </collapse-item>
      </collapse>

      <!-- <button type="button" class="btn btn-lg btn-secondary btn-round">
        Search
      </button> -->
    </div>
  </div>
</template>

<script>
import {
  FormGroupInput,
  DropDown,
  Checkbox,
  Collapse,
  CollapseItem,
  Slider,
} from "@/components";
export default {
  name: "card",
  components: {
    [FormGroupInput.name]: FormGroupInput,
    [DropDown.name]: DropDown,
    Collapse,
    CollapseItem,
    Slider,
    [Checkbox.name]: Checkbox,
  },
  props: {
    availableTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    availablePlaces: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      allJobs: false,
      allLocations: false,
    };
  },

  watch: {
    availableTypes() {
      this.allJobs = !this.availableTypes.some((type) => !type.selected);
      this.$emit(
        "filtersChange",
        this.availableTypes
          .filter((job) => job.selected)
          .map((job) => job.value),
        this.allJobs,
        this.availablePlaces
          .filter((place) => place.selected)
          .map((job) => job.value),
        this.allLocations
      );
    },
    availablePlaces() {
      this.allLocations = !this.availablePlaces.some(
        (place) => !place.selected
      );
      this.$emit(
        "filtersChange",
        this.availableTypes
          .filter((job) => job.selected)
          .map((job) => job.value),
        this.allJobs,
        this.availablePlaces
          .filter((place) => place.selected)
          .map((job) => job.value),
        this.allLocations
      );
    },
  },

  methods: {
    changeAllJobs(value) {
      this.availableTypes.forEach((job) => {
        job.selected = value;
      });
      this.$emit(
        "filtersChange",
        this.availableTypes
          .filter((job) => job.selected)
          .map((job) => job.value),
        value,
        this.availablePlaces
          .filter((place) => place.selected)
          .map((job) => job.value),
        this.allLocations
      );
    },

    changeAllLocations(value) {
      this.availablePlaces.forEach((place) => {
        place.selected = value;
      });
      this.$emit(
        "filtersChange",
        this.availableTypes
          .filter((job) => job.selected)
          .map((job) => job.value),
        this.allJobs,
        this.availablePlaces
          .filter((place) => place.selected)
          .map((job) => job.value),
        value
      );
    },

    changeJob(job) {
      if (!job.selected && this.allJobs) {
        this.allJobs = false;
      }
      if (
        job.selected &&
        !this.allJobs &&
        this.availableTypes.every((otherJob) => otherJob.selected)
      ) {
        this.allJobs = true;
      }
      this.$emit(
        "filtersChange",
        this.availableTypes
          .filter((job) => job.selected)
          .map((job) => job.value),
        this.allJobs,
        this.availablePlaces
          .filter((place) => place.selected)
          .map((job) => job.value),
        this.allLocations
      );
    },

    changePlace(place) {
      if (!place.selected && this.allLocations) {
        this.allLocations = false;
      }
      if (
        place.selected &&
        !this.allLocations &&
        this.availablePlaces.every((otherPlace) => otherPlace.selected)
      ) {
        this.allLocations = true;
      }
      this.$emit(
        "filtersChange",
        this.availableTypes
          .filter((job) => job.selected)
          .map((job) => job.value),
        this.allJobs,
        this.availablePlaces
          .filter((place) => place.selected)
          .map((job) => job.value),
        this.allLocations
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/now-ui-kit/_variables.scss";

.find-card {
  background-color: $primary-color;
}
</style>
