<template>
  <div class="container">
    <div v-if="similarJobs.length > 0">
      <div class="separator-line separator-primary" />
      <div class="row">
        <div class="col-12">
          <h3>Maybe of interest</h3>
        </div>

        <div v-for="(job, i) in similarJobs" :key="i" class="col-md-6">
          <Card
            :title="job.title"
            :place="job.place"
            :date="job.date"
            :description="job.description"
            :jobId="job.id"
            :category="job.type"
            style="border-radius: 10px"
          ></Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./Cards/Card.vue";
import { db } from "@/firebase";
import {
  ref,
  onValue,
  limitToFirst,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { METHODS } from "http";

export default {
  name: "SimilarJobs",
  props: {
    currentJobId: String,
    jobType: String,
  },
  components: {
    Card,
  },
  data() {
    return {
      similarJobs: [],
    };
  },

  created() {
    this.getSimilarJobs();
  },

  watch: {
    currentJobId() {
      this.getSimilarJobs();
    },
  },

  methods: {
    getSimilarJobs() {
      const jobs = query(
        ref(db, "Jobs"),
        orderByChild("type"),
        equalTo(this.jobType),
        limitToFirst(3)
      );
      onValue(jobs, (snapshot) => {
        let aux = [];
        snapshot.forEach((childSnapshot) => {
          if (childSnapshot.key != this.currentJobId) {
            let auxJob = childSnapshot.val();
            auxJob.id = childSnapshot.key;
            aux.push(auxJob);
          }
        });
        this.similarJobs = aux.slice(0, 2);
      });
    },
  },
};
</script>
