<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <div class="container pl-0">
        <div class="row">
          <div class="col hide-on-mobile">
            <a v-popover:popover1 class="navbar-brand" href="/">
              <img
                src="../../public/img/reach-hr-logo.png"
                alt="Reach-HR"
                style="
                  border-radius: 3%;
                  height: 70px;
                  border-style: solid;
                  border-width: 2px;
                "
              />
            </a>
          </div>
        </div>
        <div class="col">
          <a href="/" class="logo-font">
            <div class="row">
              <div class="col-12 logo-font__name">Reach Health Recruitment</div>
              <div class="col-12 logo-font__slogan">
                Providing Global Solutions
              </div>
            </div>
          </a>
        </div>
      </div>
    </template>
    <template slot="navbar-menu">
      <my-drop-down tag="li" title="Browse jobs" class="nav-item">
        <div class="container">
          <div class="row" style="width: 100%">
            <div class="col-12">
              <p style="font-size: 12px">
                <b>POPULAR JOB TYPES</b>
              </p>
              <li v-for="(type, i) in typesOptions" :key="i">
                <router-link class="jobOption" :to="type.to">{{
                  type.label
                }}</router-link>
              </li>

              <li class="mt-1">
                <router-link class="jobOption" to="/jobs"
                  >View all categories</router-link
                >
              </li>
            </div>

            <div class="col-12 mt-3">
              <p style="font-size: 12px">
                <b> POPULAR LOCATIONS</b>
              </p>

              <li v-for="(location, i) in locationsOptions" :key="i">
                <router-link class="jobOption" :to="location.to">
                  {{ location.label }}</router-link
                >
              </li>

              <li class="mt-1">
                <router-link class="jobOption" to="/jobs">
                  View all locations</router-link
                >
              </li>
            </div>
          </div>
        </div>
      </my-drop-down>

      <li class="nav-item">
        <router-link class="nav-link" to="/companycontactform">
          <AboutUsIcon class="nav-icon" />
          <p>COMPANIES</p>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link class="nav-link" to="/aboutus">
          <AboutUsIcon class="nav-icon" />
          <p>About us</p>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link class="nav-link" to="/contactus">
          <ContactIcon class="nav-icon" />
          <p>Contact us</p>
        </router-link>
      </li>
    </template>
  </navbar>
</template>

<script>
import ContactIcon from "vue-material-design-icons/EmailOutline.vue";
import AboutUsIcon from "vue-material-design-icons/AccountGroupOutline.vue";
import { MyDropDown, Navbar } from "@/components";
import { Popover } from "element-ui";
export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    MyDropDown,
    Navbar,
    [Popover.name]: Popover,
    ContactIcon,
    AboutUsIcon,
  },
  data() {
    return {
      typesOptions: [
        // { to: "/jobs?t=0", label: "Doctors jobs" },
        { to: "/jobs?t=1", label: "Nursing jobs" },
        // { to: "/jobs?t=2", label: "Allied health jobs" },
        { to: "/jobs?t=3", label: "Healthcare assistants jobs" },
      ],
      locationsOptions: [
        { to: "/jobs?l=0", label: "Jobs in London" },
        { to: "/jobs?l=1", label: "Jobs in Wales" },
        { to: "/jobs?l=2", label: "Jobs in Scotland" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap");
@import "../assets/scss/now-ui-kit/_variables.scss";

.logo-font {
  font-family: "Ropa Sans", sans-serif;
  text-transform: capitalize;
  &__name {
    font-size: 22px;
    line-height: 20px;
    color: rgba(255, 255, 255, 1);
  }
  &__slogan {
    font-size: 22px;
    line-height: 20px;
    color: rgba(245, 245, 245, 0.767);
  }
}

.logo-font:hover {
  text-decoration: none;
}
.nav-link {
  font-size: 12px !important;
  white-space: nowrap;
}

.nav-icon {
  margin-right: 4px;
}

.jobOption {
  text-decoration: none;
  color: $black-color !important;
  transition: color 0.2s ease;
}

.jobOption:hover {
  color: $secondary-color !important;
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }

  p {
    color: $white-color !important;
  }

  .jobOption {
    text-decoration: none;
    color: $white-color !important;
    transition: color 0.2s ease;
  }
}
</style>
