<template>
  <div>
    <div class="page-header page-header-small clear-filter" filter-color="blue">
      <parallax
        class="page-header-image"
        style="background-image: url('img/jobs.jpeg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Unlock your potential with a new job</h1>
        </div>
      </div>
    </div>
    <div class="section section-form-page">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <fg-input
              v-model="searchText"
              class="input-lg"
              placeholder="Search..."
              addon-left-icon="now-ui-icons ui-1_zoom-bold"
              @keyup.enter="updateJobsList"
            >
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <SearchJobCard
              :availableTypes="availableTypes"
              :availablePlaces="availablePlaces"
              @filtersChange="updateJobsList"
            />
          </div>
          <div class="col-12 col-md-8">
            <h3
              v-if="updateJobsBySearch.length == 0"
              style="text-align: center"
              class="mt-5"
            >
              Sorry, we couldn't find any job openings that match your selected
              preferences.
            </h3>

            <div
              v-for="i in visibleJobs"
              :key="updateJobsBySearch[i - 1].id"
              class="row px-2"
            >
              <Card
                :title="updateJobsBySearch[i - 1].title"
                :place="updateJobsBySearch[i - 1].placeTitle"
                :date="updateJobsBySearch[i - 1].date"
                :description="updateJobsBySearch[i - 1].description"
                :jobId="updateJobsBySearch[i - 1].id"
                :category="updateJobsBySearch[i - 1].type"
                style="border-radius: 10px"
              ></Card>
            </div>

            <div class="row justify-content-center">
              <nav aria-label="Page navigation example">
                <button
                  v-if="!(jobsList.length < maxJobsPage * page)"
                  class="btn btn-secondary btn-round"
                  :disabled="loadingJobs"
                  @click="loadMoreJobs()"
                >
                  <span v-if="!loadingJobs"> Load more </span>
                  <i
                    v-if="loadingJobs"
                    class="now-ui-icons loader_refresh spin"
                  ></i>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "../components/Cards/Card.vue";
import SearchJobCard from "../components/Cards/SearchJobCard.vue";
import { db } from "@/firebase";
import { ref, onValue, query, orderByChild, equalTo } from "firebase/database";
import { FormGroupInput } from "@/components";

export default {
  name: "profile",
  bodyClass: "landing-page",
  components: {
    Card,
    SearchJobCard,
    [FormGroupInput.name]: FormGroupInput,
  },

  data() {
    return {
      jobsList: [],
      availablePlaces: [],
      availableTypes: [],
      loadingJobs: false,
      typeFilter: [],
      locationFilter: [],
      allTypesOption: true,
      allLocationsOption: true,
      changeFlag: 0,
      filteredJobs: [],
      queryString: null,
      searchText: "",
      maxJobsPage: 10,
      page: 1,
    };
  },

  mounted() {
    // Chame a função para detectar a mudança inicial da query string
    this.detectQueryStringChange();

    // Use a propriedade $route para assistir às mudanças na query string
    this.$watch(
      "$route.query",
      () => {
        // Chame a função para detectar a mudança na query string
        this.detectQueryStringChange();
      },
      { deep: true }
    );
  },

  created() {
    const jobs = query(
      ref(db, "Jobs"),
      orderByChild("status"),
      equalTo("not filled")
    );
    onValue(jobs, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        this.jobsList.push({
          id: childSnapshot.key,
          date: childSnapshot.val().date,
          description: childSnapshot.val().description,
          place: childSnapshot.val().place,
          title: childSnapshot.val().title,
          type: childSnapshot.val().type,
          placeTitle: childSnapshot.val().placeTitle,
        });
      });
      this.updateSearchFilters();
    });
  },

  computed: {
    visibleJobs() {
      return this.page * this.maxJobsPage < this.updateJobsBySearch.length
        ? this.page * this.maxJobsPage
        : this.updateJobsBySearch.length;
    },

    updateJobsBySearch() {
      let aux = JSON.parse(JSON.stringify(this.filteredJobs));
      if (this.searchText) {
        let auxText = this.searchText.toLowerCase();
        aux = aux.filter((job) => {
          return (
            job.title.toLowerCase().includes(auxText) ||
            job.description.toLowerCase().includes(auxText) ||
            job.place.toLowerCase().includes(auxText) ||
            job.type.toLowerCase().includes(auxText)
          );
        });
      }
      return aux;
    },
  },

  methods: {
    detectQueryStringChange() {
      this.queryString = this.$route.query;
      this.updateSearchFilters();
    },

    updateSearchFilters() {
      var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };

      this.filteredJobs = JSON.parse(JSON.stringify(this.jobsList));
      this.availableTypes = Object.entries(groupBy(this.jobsList, "type")).map(
        (job) => {
          switch (this.$route.query.t) {
            case "0":
              return {
                label: job[0],
                value: job[0],
                selected: job[0].toLocaleLowerCase() == "doctor",
                count: job[1].length,
              };
            case "1":
              return {
                label: job[0],
                value: job[0],
                selected: job[0].toLocaleLowerCase() == "nurse",
                count: job[1].length,
              };
            case "2":
              return {
                label: job[0],
                value: job[0],
                selected: job[0].toLocaleLowerCase() == "allied health",
                count: job[1].length,
              };
            case "3":
              return {
                label: job[0],
                value: job[0],
                selected: job[0].toLocaleLowerCase() == "healthcare assistant",
                count: job[1].length,
              };
            default:
              return {
                label: job[0],
                value: job[0],
                selected: true,
                count: job[1].length,
              };
          }
        }
      );

      this.availablePlaces = Object.entries(
        groupBy(this.jobsList, "place")
      ).map((job) => {
        switch (this.$route.query.l) {
          case "0":
            return {
              label: job[0],
              value: job[0],
              selected: job[0].toLocaleLowerCase() == "london",
              count: job[1].length,
            };
          case "1":
            return {
              label: job[0],
              value: job[0],
              selected: job[0].toLocaleLowerCase() == "wales",
              count: job[1].length,
            };
          case "2":
            return {
              label: job[0],
              value: job[0],
              selected: job[0].toLocaleLowerCase() == "scotland",
              count: job[1].length,
            };
          default:
            return {
              label: job[0],
              value: job[0],
              selected: true,
              count: job[1].length,
            };
        }
      });
    },

    loadMoreJobs() {
      this.loadingJobs = true;
      // const jobs = query(
      //   ref(db, "Jobs"),
      //   orderByKey(),
      //   startAt(`${this.maxJobsPage * this.page + 1}`),
      //   limitToFirst(this.maxJobsPage)
      // );
      // onValue(
      //   jobs,
      //   (snapshot) => {
      //     snapshot.forEach((childSnapshot) => {
      //       this.jobsList.push({
      //         id: childSnapshot.key,
      //         date: childSnapshot.val().date,
      //         description: childSnapshot.val().description,
      //         place: childSnapshot.val().place,
      //         title: childSnapshot.val().title,
      //         type: childSnapshot.val().type,
      //         placeTitle: childSnapshot.val().placeTitle,
      //       });
      //     });
      //     this.updateSearchFilters();
      //     this.loadingJobs = false;
      //   },
      //   (error) => {
      //     console.error(error);
      //     this.loadingJobs = false;
      //   }
      // );
      this.page++;
    },

    updateJobsList(typeFilter, typeFlag, locationFilter, locationFlag) {
      let result = JSON.parse(JSON.stringify(this.jobsList));
      if (!typeFlag) {
        result = result.filter((job) => typeFilter.includes(job.type));
      }
      if (!locationFlag) {
        result = result.filter((job) => locationFilter.includes(job.place));
      }
      this.filteredJobs = result;
    },
  },
};
</script>

<style lang="scss" scoped></style>
