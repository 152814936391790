import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyC_1nyPowKUtxJ4Ba9HAzTvdNzjgrDjc_U",
  authDomain: "reach-hr.firebaseapp.com",
  databaseURL: "https://reach-hr-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "reach-hr",
  storageBucket: "reach-hr.appspot.com",
  messagingSenderId: "661408779751",
  appId: "1:661408779751:web:4c5b27b939d71734ad8142",
  measurementId: "G-L3FGT5GPRZ",
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getDatabase(app);

export {
    db
}
