<template>
  <div
    class="card border-primary mb-3 job-card"
    @click="$router.push('/jobdetails/' + jobId)"
  >
    <div class="card-header">
      <slot name="header">
        <h4 v-if="title" class="my-0">{{ title }}</h4>
        <h6 v-if="category" class="card-category mt-2">
          <i class="now-ui-icons business_badge" style="margin-right: 6px"></i>
          {{ category }}
        </h6>

        <h6 v-if="place" class="card-category">
          <i class="now-ui-icons location_pin" style="margin-right: 6px"></i>
          {{ place }}
        </h6>
        <h6 v-if="date" class="card-category">
          <i
            class="now-ui-icons ui-1_calendar-60"
            style="margin-right: 6px"
          ></i>
          {{ date }}
        </h6>
        <span v-if="description" class="card-description">
          {{
            description.length > 150
              ? description.substring(0, 150) + "..."
              : description
          }}
        </span>
      </slot>
    </div>

    <!-- <div class="container">
      <div class="row">
        <div class="col text-center">
          <button type="button" class="btn btn-primary btn-round">
            show more
          </button>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Button from "../../components/Button.vue";
export default {
  name: "card",
  comments: {
    Button,
  },
  props: {
    type: String,
    title: String,
    place: String,
    date: String,
    category: String,
    color: String,
    description: String,
    noFooterLine: Boolean,
    plain: Boolean,
    raised: Boolean,
    cardBodyClasses: [String, Object, Array],
    headerClasses: [String, Object, Array],
    jobId: String,
  },
};
</script>

<style lang="scss" scoped>
.job-card {
  box-shadow: 1px 1px 5px grey;
  padding: 12px !important;
  transition: background-color 0.2s ease;
}
.job-card:hover {
  background-color: rgba(0, 100, 183, 0.05);
  cursor: pointer;
  // box-shadow: 3px 3px 5px grey;
}
</style>
