<template>
  <div class="wrapper">
    <div class="section">
      <div class="about-description">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="mb-2">
                {{ job.title }}
                <ShareNetwork
                  network="facebook"
                  :url="`https://reach-hr.web.app/#/jobdetails/${this.$route.params.id}`"
                  hashtags="healthcare,jobs"
                  title="Reach Health Recruitment"
                >
                  <Share style="color: #de0059; font-size: 48px" />
                </ShareNetwork>
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p style="font-weight: 500">
                <i
                  class="now-ui-icons business_badge"
                  style="margin-right: 6px"
                ></i>
                {{ job.type }}
              </p>

              <p style="font-weight: 500">
                <i
                  class="now-ui-icons location_pin"
                  style="margin-right: 6px"
                ></i>
                {{ job.placeTitle }}
              </p>

              <p style="font-weight: 500">
                <i
                  class="now-ui-icons ui-1_calendar-60"
                  style="margin-right: 6px"
                ></i>
                {{ job.date }}
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <p
                style="white-space: pre-line; font-weight: 400"
                v-html="job.fullDescription"
              ></p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button
                type="submit"
                class="btn btn-secondary btn-round btn-lg"
                @click="showModal = true"
              >
                Apply now
              </button>
            </div>
          </div>
        </div>
      </div>
      <similar-jobs
        v-if="job.type"
        :currentJobId="$route.params.id"
        :jobType="job.type"
      ></similar-jobs>
    </div>

    <Modal :show="showModal" :showClose="false" @close="closeModal">
      <div class="row">
        <div class="col-12">
          <alert
            :visible="successFlag"
            style="border-radius: 5px"
            type="success"
            :dismissible="true"
            @close="hideSuccessAlert"
            >Successfully applied, we will be in touch shortly.</alert
          >
          <alert
            v-if="errorFlag"
            style="border-radius: 5px"
            type="danger"
            :dismissible="true"
            @close="hideDangerAlert"
            >Error applying, please try again later.</alert
          >
        </div>
        <div class="col-md-6">
          <fg-input
            id="fname"
            v-model="form.firstName"
            addon-left-icon="now-ui-icons users_circle-08"
            placeholder="First name..."
            :required="true"
            :hideError="newForm"
            label="First name"
            @validation="updateFormValidation($event, 0)"
          />
        </div>

        <div class="col-md-6">
          <fg-input
            v-model="form.lastName"
            addon-left-icon="now-ui-icons users_circle-08"
            placeholder="Last name..."
            :required="true"
            :hideError="newForm"
            label="Last name"
            @validation="updateFormValidation($event, 1)"
          />
        </div>

        <div class="col-md-12">
          <fg-input
            v-model="form.email"
            addon-left-icon="now-ui-icons ui-1_email-85"
            placeholder="Email Here..."
            :required="true"
            label="Email"
            :errorMsg="emailError"
            @validation="updateFormValidation($event, 2)"
          />
        </div>
        <div class="col-md-6">
          <fg-input label="Job type">
            <el-select v-model="form.job" class="select-default">
              <el-option
                class="select-default"
                value="Doctor"
                label="I'm a Doctor"
              />
              <el-option
                class="select-default"
                value="Nurse"
                label="I'm a Nurse"
              />
              <el-option
                class="select-default"
                value="Healthcare Assistant"
                label="I'm a Healthcare Assistant"
              />
              <el-option
                class="select-default"
                value="Allied Health"
                label="I'm a Allied Health"
              />
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-6">
          <fg-input label="Country">
            <el-select
              v-model="form.country"
              class="select-default"
              style="height: 40px"
            >
              <el-option
                v-for="c in countries"
                :key="c.value"
                class="select-default"
                :value="c.value"
                :label="c.name"
              />
            </el-select>
          </fg-input>
        </div>

        <div class="col-md-12">
          <fg-input
            addon-left-icon="now-ui-icons arrows-1_cloud-upload-94"
            :placeholder="cvPlaceholder"
            inputClasses="my-file-input"
            :readonly="true"
            @click="uploadCV"
            label="Curriculum Vitae"
          />
          <input
            id="fileInputHidden"
            ref="fileInputHidden"
            type="file"
            accept="application/pdf"
            @change="updateFilename"
          />
        </div>

        <div class="col-12">
          <n-checkbox
            v-model="form.notifyNewJobs"
            :checked="true"
            :inline="true"
            >I intend to receive updates on similar jobs</n-checkbox
          >
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 ml-auto mr-auto text-center">
          <button
            class="btn btn-secondary btn-round mt-4 btn-lg"
            :class="{ disabled: disableSubmit || loading }"
            @click="submitForm"
          >
            <span v-if="!loading"> Apply </span>
            <i v-else class="now-ui-icons loader_refresh spin"></i>
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Badge,
  FormGroupInput,
  Checkbox,
  Modal,
  Alert,
} from "@/components";
import { Select, Option } from "element-ui";
import { db } from "@/firebase";
import { ref, onValue, query, getDatabase, set } from "firebase/database";
import {
  getStorage,
  ref as refStorage,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import Share from "vue-material-design-icons/ShareVariant.vue";
import countries from "../../countries.json";
import SimilarJobs from "../components/SimilarJobs.vue";

export default {
  name: "AboutUs",

  bodyClass: "about-us",
  components: {
    Card,
    Badge,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Select.name]: Select,
    [Option.name]: Option,
    Share,
    Modal,
    SimilarJobs,
    [Alert.name]: Alert,
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        job: "Doctor",
        country: 1,
        email: "",
        cv: "",
        notifyNewJobs: true,
      },
      job: {},
      showModal: false,
      cvPlaceholder: "CV Here...",
      countries: countries,
      valiationArray: [false, false, false],
      flag: 0,
      emailError: "",
      loading: false,
      newForm: true,
      successFlag: false,
      errorFlag: false,
    };
  },
  created() {
    this.getJobDetails();
  },

  computed: {
    disableSubmit() {
      this.flag;
      return (
        !this.validateEmail(this.form.email) ||
        this.valiationArray.some((val) => !val)
      );
    },
  },

  watch: {
    "$route.params.id"() {
      this.getJobDetails();
    },
  },

  methods: {
    hideDangerAlert() {
      this.errorFlag = false;
    },

    hideSuccessAlert() {
      this.successFlag = false;
    },

    getJobDetails() {
      const job = query(ref(db, "Jobs/" + this.$route.params.id));
      onValue(job, (snapshot) => {
        this.job = snapshot.val();
      });
    },

    validateEmail(email) {
      var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (email !== "" && email.match(emailFormat)) {
        this.emailError = "";
        return true;
      } else {
        if (!this.newForm) {
          this.emailError = "Invalid email";
          return false;
        } else {
          return true;
        }
      }
    },

    closeModal() {
      this.showModal = false;
      this.errorFlag = false;
      this.successFlag = false;
    },

    uploadCV() {
      this.$refs.fileInputHidden.click();
    },

    updateFilename() {
      if (document.getElementById("fileInputHidden").files[0]) {
        this.cvPlaceholder =
          document.getElementById("fileInputHidden").files[0].name;
      } else {
        this.cvPlaceholder = "CV Here...";
      }
      this.form.cv = document.getElementById("fileInputHidden").files[0];
    },

    generateRandomId(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    updateFormValidation(event, index) {
      index == 2 ? (this.newForm = false) : null;
      this.valiationArray[index] = event;
      this.flag++;
    },

    submitForm() {
      if (this.disableSubmit || this.loading) {
        this.newForm = false;
      } else {
        this.loading = true;
        this.errorFlag = false;
        this.successFlag = false;
        console.log("CV Upload...");
        try {
          const storage = getStorage();
          const fileRef = refStorage(
            storage,
            "gs://reach-hr/cvs/" + this.generateRandomId(16) + ".pdf"
          );
          if (document.getElementById("fileInputHidden").files[0]) {
            uploadBytes(
              fileRef,
              document.getElementById("fileInputHidden").files[0]
            )
              .then((snapshot) => {
                getDownloadURL(snapshot.ref)
                  .then((url) => {
                    this.saveData(url);
                  })
                  .catch((error) => {
                    console.error(
                      "Erro ao obter a URL de download do arquivo:",
                      error
                    );
                    this.errorFlag = true;
                    return null;
                  });
              })
              .catch((error) => {
                console.error("Erro ao fazer o upload do arquivo:", error);
                this.errorFlag = true;
                return null;
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.saveData("");
          }
        } catch (error) {
          console.error(error);
          this.errorFlag = true;
          this.loading = false;
        }
      }
    },

    saveData(cvURL) {
      console.log("A criar candidato...");
      let today = new Date();
      let formattedToday = `${today.getFullYear()}/${today.getMonth()}/${today.getDate()}`;
      let auxToday = `${today.getFullYear()}${today.getMonth()}${today.getDate()}`;
      const db = getDatabase();
      set(ref(db, "/Candidatos/" + auxToday + "-" + this.generateRandomId(8)), {
        status: "new",
        "data nascimento": "",
        email: this.form.email,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        country: this.form.country,
        job: this.form.job,
        cvURL: cvURL,
        jobId: this.$route.params.id,
        notifyNewJobs: this.form.notifyNewJobs,
        type: this.form.job,
        date: formattedToday,
      })
        .then(() => {
          console.log("Novo candidato adicionado");
          this.form = {
            firstName: "",
            lastName: "",
            job: "Doctor",
            country: 1,
            email: "",
            cv: "",
            notifyNewJobs: true,
          };
          document.getElementById("fileInputHidden").value = "";
          this.newForm = true;
          this.cvPlaceholder = "CV Here...";
          this.successFlag = true;
        })
        .catch((error) => {
          console.error(error);
          this.errorFlag = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
#fileInputHidden {
  display: none;
}
</style>
