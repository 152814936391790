<template>
  <component
    class="dropdown"
    :is="tag"
    :class="[
      { show: isOpen },
      { dropdown: direction === 'down' },
      { dropup: direction === 'up' },
    ]"
    aria-haspopup="true"
    :aria-expanded="isOpen"
    @click="toggleDropDown"
    v-click-outside="closeDropDown"
  >
    <slot name="title">
      <a
        class="dropdown-toggle nav-link"
        :class="{ 'no-caret': hideArrow, 'my-dropdown-icon': true }"
        data-toggle="dropdown"
      >
        <Stethoscope class="nav-icon" />
        <span class="no-icon">{{ title }}</span>
      </a>
    </slot>
    <ul
      class="dropdown-menu"
      :class="[{ 'dropdown-menu-left': position === 'left' }, { show: isOpen }]"
    >
      <slot></slot>
    </ul>
  </component>
</template>
<script>
import Stethoscope from "vue-material-design-icons/Stethoscope.vue";

export default {
  name: "my-drop-down",
  components: {
    Stethoscope,
  },
  props: {
    direction: {
      type: String,
      default: "down",
    },
    title: String,
    icon: String,
    position: {
      type: String,
      default: "left",
    },
    hideArrow: Boolean,
    tag: {
      type: String,
      default: "li",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  provide() {
    return {
      closeDropDown: this.closeDropDown,
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
      this.$emit("change", this.isOpen);
    },
    closeDropDown() {
      this.isOpen = false;
      this.$emit("change", this.isOpen);
    },
  },
};
</script>

<style scoped>
.dropdown {
  list-style-type: none;
}

.dropdown .dropdown-toggle {
  cursor: pointer;
}

.no-icon {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.my-dropdown-icon {
  font-size: 14px !important;
}

.nav-icon {
  margin-right: 8px;
}

.dropdown-menu {
  width: 250px
}
</style>
