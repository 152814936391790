import Vue from "vue";
import Router from "vue-router";
import Index from "./pages/Index.vue";
import Landing from "./pages/Landing.vue";
import Login from "./pages/Login.vue";
import Profile from "./pages/Profile.vue";
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";
import Jobs from "./pages/Jobs.vue";
import JobDetails from "./pages/Jobdetails.vue";
import ContactUs from "./pages/Contactus.vue";
import AboutUs from "./pages/Aboutus.vue";
import CompanyContactForm from "./pages/Companycontactform.vue";
import Meta from "vue-meta";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "index",
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 10 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/jobs",
      name: "jobs",
      components: { default: Jobs, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 10 },
        footer: { backgroundColor: "black" },
      },
    },

    {
      path: "/jobdetails/:id",
      name: "jobdetails",
      components: {
        default: JobDetails,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 0 },
        footer: { backgroundColor: "black" },
      },
    },

    {
      path: "/contactus",
      name: "contactus",
      components: {
        default: ContactUs,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 10 },
        footer: { backgroundColor: "black" },
      },
    },

    {
      path: "/Companycontactform",
      name: "CompanyContactForm",
      components: {
        default: CompanyContactForm,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 10 },
        footer: { backgroundColor: "black" },
      },
    },

    {
      path: "/aboutus",
      name: "aboutus",
      components: { default: AboutUs, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 10 },
        footer: { backgroundColor: "black" },
      },
    },

    {
      path: "/landing",
      name: "landing",
      components: { default: Landing, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/login",
      name: "login",
      components: { default: Login, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
      },
    },
    {
      path: "/profile",
      name: "profile",
      components: { default: Profile, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// // This callback runs before every route change, including on page load.
// router.beforeEach((to, from, next) => {
//   // This goes through the matched routes from last to first, finding the closest route with a title.
//   // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
//   // `/nested`'s will be chosen.
//   const nearestWithTitle = to.matched
//     .slice()
//     .reverse()
//     .find((r) => r.meta && r.meta.title);

//   // Find the nearest route element with meta tags.
//   const nearestWithMeta = to.matched
//     .slice()
//     .reverse()
//     .find((r) => r.meta && r.meta.metaTags);

//   const previousNearestWithMeta = from.matched
//     .slice()
//     .reverse()
//     .find((r) => r.meta && r.meta.metaTags);

//   // If a route with a title was found, set the document (page) title to that value.
//   if (nearestWithTitle) {
//     document.title = nearestWithTitle.meta.title;
//   } else if (previousNearestWithMeta) {
//     document.title = previousNearestWithMeta.meta.title;
//   }

//   // Remove any stale meta tags from the document using the key attribute we set below.
//   Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
//     (el) => el.parentNode.removeChild(el)
//   );

//   // Skip rendering meta tags if there are none.
//   if (!nearestWithMeta) return next();

//   // Turn the meta tag definitions into actual elements in the head.
//   nearestWithMeta.meta.metaTags
//     .map((tagDef) => {
//       const tag = document.createElement("meta");

//       Object.keys(tagDef).forEach((key) => {
//         tag.setAttribute(key, tagDef[key]);
//       });

//       // We use this to track which meta tags we create so we don't interfere with other ones.
//       tag.setAttribute("data-vue-router-controlled", "");

//       return tag;
//     })
//     // Add the meta tags to the document head.
//     .forEach((tag) => document.head.appendChild(tag));

//   next();
// });

export default router;
