var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",class:[
    { 'input-group': _vm.hasIcon },
    { 'has-danger': (_vm.requireError || _vm.errorMsg) && !_vm.hideError },
    { 'input-group-focus': _vm.focused },
    { 'has-label': _vm.label || _vm.$slots.label },
  ]},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"form-label ml-2",class:_vm.labelClasses,attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]}),_c('div',{staticClass:"mb-0",class:[{ 'input-group': _vm.hasIcon }]},[_vm._t("addonLeft",function(){return [(_vm.addonLeftIcon)?_c('div',{staticClass:"input-group-prepend"},[_c('i',{staticClass:"input-group-text",class:_vm.addonLeftIcon})]):_vm._e()]}),_vm._t("default",function(){return [_c('input',_vm._g(_vm._b({ref:"input",staticClass:"form-control",class:[{ valid: _vm.value && !_vm.error }, _vm.inputClasses],attrs:{"id":_vm.id,"aria-describedby":"addon-right addon-left","readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":_vm.requireValidation}},'input',_vm.$attrs,false),_vm.listeners))]})],2),_c('transition',{attrs:{"name":"slide-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        (_vm.errorMsg && !_vm.hideError) ||
        (!_vm.hideError && _vm.required ? _vm.requireError : false)
      ),expression:"\n        (errorMsg && !hideError) ||\n        (!hideError && required ? requireError : false)\n      "}]},[(!_vm.hideError && _vm.required ? _vm.requireError : false)?_c('div',{staticClass:"input-message"},[_vm._v(" This field is mandatory ")]):_c('div',{staticClass:"input-message"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }