<template>
  <div>
    <div class="page-header page-header-small clear-filter" filter-color="blue">
      <parallax
        class="page-header-image"
        style="background-image: url('img/contactus.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Contact us</h1>
        </div>
      </div>
    </div>
    <div class="section section-contact-us text-center section-form-page">
      <div class="container">
        <h3 class="title">GENERAL ENQUIRIES</h3>
        <p>
          Please contact us at Reach Health Recruitment with your questions or
          comments and our dedicated team will be happy to get you a response
          the soonest possible.
        </p>
        <div class="row">
          <div class="col-12">
            <alert
              :visible="successFlag"
              style="border-radius: 5px"
              type="success"
              :dismissible="true"
              @close="hideSuccessAlert"
              >Email successfully sent, we will be in touch very soon.</alert
            >
            <alert
              v-if="errorFlag"
              style="border-radius: 5px"
              type="danger"
              :dismissible="true"
              @close="hideDangerAlert"
              >An error occurred while sending the email. Please try again
              later.</alert
            >
          </div>
          <div class="col-12 text-center ml-auto mr-auto">
            <fg-input
              class="input-lg px-0"
              placeholder="Your name..."
              v-model="form.name"
              addon-left-icon="now-ui-icons users_circle-08"
              :required="true"
              :hideError="!validateFlag"
            >
            </fg-input>
          </div>
          <div class="col-12 text-center ml-auto mr-auto">
            <fg-input
              class="input-lg px-0"
              placeholder="Email Here..."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
              :errorMsg="emailError"
              :hideError="!validateFlag"
            >
            </fg-input>
          </div>
          <div class="col-12 text-center ml-auto mr-auto">
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                placeholder="Type a message..."
                style="border-radius: 30px; background-color: white; padding: 16px;"
              ></textarea>
            </div>
            <div class="send-button">
              <n-button
                :class="{ disabled: submitDisabled }"
                type="secondary"
                round
                size="lg"
                @click="sendMessage"
              >
                <span v-if="!loading"> Send Message </span>
                <i v-else class="now-ui-icons loader_refresh spin"></i>
              </n-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, FormGroupInput, Alert } from "@/components";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  name: "contactus",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Alert.name]: Alert,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
      emailError: "",
      validateFlag: false,
      successFlag: false,
      errorFlag: false,
      loading: false,
    };
  },
  methods: {
    hideDangerAlert() {
      this.errorFlag = false;
    },

    hideSuccessAlert() {
      this.successFlag = false;
    },

    validateEmail(email) {
      var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (!email) {
        this.emailError = "This field is mandatory";
        return false;
      }

      if (!email.match(emailFormat)) {
        this.emailError = "Invalid email";
        return false;
      }

      this.emailError = "";
      return true;
    },

    sendMessage() {
      if (this.submitDisabled || this.loading) {
        this.validateFlag = true;
      } else {
        this.loading = true;
        console.log("A enviar mensagem...");
        const functions = getFunctions();
        const sendEmail = httpsCallable(functions, "sendEmailGeneral");
        sendEmail(this.form)
          .then(() => {
            this.errorFlag = false;
            this.form.email = "";
            this.form.name = "";
            this.form.message = "";
            this.validateFlag = false;
            this.successFlag = true;
          })
          .catch((error) => {
            this.successFlag = false;
            this.errorFlag = true;
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  computed: {
    submitDisabled() {
      return !this.validateEmail(this.form.email) || !this.form.name;
    },
  },
};
</script>
<style></style>
