<template>
  <div>
    <div class="page-header page-header-small clear-filter" filter-color="blue">
      <parallax
        class="page-header-image"
        style="background-image: url('img/aboutus.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">This is our great company</h1>
          <div class="text-center">
            <a
              href="https://www.facebook.com/ReachHR"
              target="_blank"
              class="btn btn-secondary btn-icon btn-round mx-2"
            >
              <i class="fab fa-facebook"></i>
            </a>
            <a
              href="https://twitter.com/ReachHR1"
              target="_blank"
              class="btn btn-secondary btn-icon btn-round mx-2"
            >
              <i class="fab fa-twitter"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/reach-health-recruitment/"
              target="_blank"
              class="btn btn-secondary btn-icon btn-round mx-2"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us section-form-page">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Who we are?</h2>
            <h5 class="text-justify">
              Reach Health Recruitment is a company specialised in the
              international recruitment of Medical and Healthcare staff. Reach
              Health Recruitment has an established track record of providing an
              individualized and effective recruitment service to both clients
              and candidates in placing a variety of medical and health
              professions into fixed term / substantive positions into the UK,
              amongst other countries. Working in a discreet, although
              transparent and professional manner, the aim of Reach Health
              Recruitment is to ensure that the needs and aspirations of both
              clients and candidates are always matched. Reach Health
              Recruitment is present in Western, Central and Eastern Europe,
              with offices in Portugal and Czech Republic and actively sourcing
              and recruiting from all the European Union country members. Reach
              Health Recruitment does not charge any fees whatsoever to any
              candidate in any circumstances.
            </h5>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 mt-4 ml-auto mr-auto text-center">
            <h2 class="title">Our values</h2>
            <div class="row">
              <div class="col-12 col-md-4">
                <img
                  height="100px"
                  style="color: red"
                  src="../assets/images/equality.png"
                  alt="Equality"
                />
                <h4>Equality</h4>
              </div>
              <div class="col-12 col-md-4">
                <img
                  height="100px"
                  src="../assets/images/diversity.png"
                  alt="Equality"
                />
                <h4>Diversity</h4>
              </div>

              <div class="col-12 col-md-4">
                <img
                  height="100px"
                  src="../assets/images/transparency.png"
                  alt="Equality"
                />
                <h4>Transparency</h4>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12 text-justify">
                <h5>
                  Reach Health Recruitment is a company committed to promoting
                  the principles of equality and diversity in the workplace, as
                  well as ensuring that recruitment processes are fair and
                  transparent. As such, we are
                  <b style="color: #de0059"
                    >recognised by the NHS as an 'Ethical Recruiter'</b
                  >
                  . Learn more at
                  <a
                    href="https://www.nhsemployers.org/articles/ethical-recruiters-list"
                    target="_blank"
                    >here</a
                  >.
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 ml-auto mr-auto mt-4 text-center">
            <h2 class="title">Media</h2>
          </div>
        </div>

        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div class="image-container image-left">
                <a
                  href="http://www.dn.pt/inicio/portugal/interior.aspx?content_id=1698780"
                  target="_blank"
                >
                  <div style="height: 100%; background-color: white">
                    <img
                      style="
                        padding: 20px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                      "
                      src="../../public/img/diarionoticiaslogo.svg"
                      alt=""
                    />
                  </div>
                </a>
                <!-- First image on the left side -->
                <!-- <p class="blockquote blockquote-primary">
                  "Reach Health Recruitment, supplies Portuguese nurses for a
                  major care home company in the UK"
                  <br />
                  <br />
                  <small>-The Guardian</small>
                </p> -->
                <div
                  class="container pt-2 mt-4"
                  style="
                    border-width: 1px;
                    border-style: solid;
                    border-color: #0064b7;
                    max-width: 250px;
                  "
                >
                  <p style="color: #0064b7">
                    "Reach Health Recruitment, supplies Portuguese nurses for a
                    major care home company in the UK"
                  </p>

                  <p style="color: #0064b7; font-weight: 400; font-size: 14px">
                    - THE GUARDIAN
                  </p>
                </div>
              </div>
              <!-- Second image on the left side of the article -->
              <div class="image-container" style="background-color: #0074ff">
                <a
                  href="https://www.youtube.com/watch?v=Lrt7905oL9w"
                  target="_blank"
                >
                  <div style="height: 100%">
                    <img
                      style="
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                      "
                      src="../../public/img/rtp-logo.png"
                      alt=""
                    />
                  </div>
                </a>
              </div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/theguardianlogo.png')"
              >
                <a
                  href="https://www.theguardian.com/society/2011/dec/20/nurses-spain-portugal-fill-gap-in-nhs"
                  target="_blank"
                >
                  <div style="height: 100%"></div>
                </a>
              </div>
              <div
                class="container pt-2 mt-4"
                style="
                  border-width: 1px;
                  border-style: solid;
                  border-color: #0064b7;
                "
              >
                <p style="color: #0064b7">
                  "Many Spanish nurses are attracted to working in the UK, says
                  Souza, because they "have proper contracts, not just work for
                  a day or two days," and the training opportunities are better.
                  "They can come to the UK and, say, in half a year get a
                  midwifery certificate, and then come back to Spain as a
                  specialist. It's very hard to get into a specialist programme
                  here.""
                </p>
                <p style="color: #0064b7">
                  "It is a similar story in Portgual where many nurses have to
                  hold down two or three jobs to make a living, says Goncalves."
                </p>
                <p style="color: #0064b7; font-weight: 400; font-size: 14px">
                  - THE GUARDIAN
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";

export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
<style></style>
