import Vue from 'vue';
import App from './App.vue';
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import VueSocialSharing from 'vue-social-sharing'

Vue.config.productionTip = false;

Vue.use(NowUiKit);
Vue.use(VueSocialSharing);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
