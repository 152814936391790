<template>
  <div>
    <div class="page-header clear-filter" filter-color="blue">
      <parallax
        class="page-header-image"
        style="background-image: url('img/homepage-hero.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="row align-items-center" style="height: 100%">
          <div class="col-md-6" style="text-align: start">
            <h1><b>Empowering healthcare through global recruitment</b></h1>
            <p>
              <b>
                Reach Health Recruitment specialises in the global recruitment
                of Health Professionals.
                <router-link to="aboutus" style="color: white"
                  >View more <ArrowRight />
                </router-link>
              </b>
            </p>
            <div class="row">
              <div class="col-12 col-md-6">
                <button
                  type="button"
                  class="btn btn-secondary secondary btn-lg btn-block"
                  style="border-radius: 40px"
                  @click="() => $router.push('jobs')"
                >
                  Browse jobs
                </button>
              </div>

              <div class="col-12 col-md-6">
                <button
                  type="button"
                  class="btn btn-secondary btn-lg btn-block"
                  style="border-radius: 40px"
                  @click="() => $router.push('companycontactform')"
                >
                  Companies
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- <SearchJobCard title="Encontre um emprego" color="orange"/> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Parallax } from "@/components";
import BasicElements from "./components/BasicElementsSection";
import Navigation from "./components/Navigation";
import TabsSection from "./components/Tabs";
import ProgressPagination from "./components/ProgressPagination";
import Notifications from "./components/Notifications";
import Typography from "./components/Typography";
import JavascriptComponents from "./components/JavascriptComponents";
import CarouselSection from "./components/CarouselSection";
import NucleoIconsSection from "./components/NucleoIconsSection";
import SignupForm from "./components/SignupForm";
import ExamplesSection from "./components/ExamplesSection";
import DownloadSection from "./components/DownloadSection";
import SearchJobCard from "../components/Cards/SearchJobCard.vue";
import ArrowRight from "vue-material-design-icons/ArrowRight.vue";

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    Parallax,
    BasicElements,
    Navigation,
    TabsSection,
    ProgressPagination,
    Notifications,
    Typography,
    JavascriptComponents,
    CarouselSection,
    NucleoIconsSection,
    SignupForm,
    ExamplesSection,
    DownloadSection,
    SearchJobCard,
    ArrowRight,
  },
};
</script>
<style></style>
